var render = function () {
  var _vm$email, _vm$email$from, _vm$email$from$emailA, _vm$email2, _vm$email2$from, _vm$email2$from$email;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', {
    attrs: {
      "href": _vm.email.webLink,
      "rel": "noopener noreferrer",
      "target": "_blank"
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-subtitle-1 primary--text font-weight-black pb-1",
    attrs: {
      "title": (_vm$email = _vm.email) === null || _vm$email === void 0 ? void 0 : (_vm$email$from = _vm$email.from) === null || _vm$email$from === void 0 ? void 0 : (_vm$email$from$emailA = _vm$email$from.emailAddress) === null || _vm$email$from$emailA === void 0 ? void 0 : _vm$email$from$emailA.address
    },
    domProps: {
      "textContent": _vm._s((_vm$email2 = _vm.email) === null || _vm$email2 === void 0 ? void 0 : (_vm$email2$from = _vm$email2.from) === null || _vm$email2$from === void 0 ? void 0 : (_vm$email2$from$email = _vm$email2$from.emailAddress) === null || _vm$email2$from$email === void 0 ? void 0 : _vm$email2$from$email.name)
    }
  }), _c('v-list-item-title', {
    staticClass: "text-body-2 primary--text"
  }, [_vm._v(_vm._s(_vm.email.subject))]), _c('v-list-item-title', {
    staticClass: "text-body-2 font-weight-bold",
    domProps: {
      "textContent": _vm._s(_vm.humanDateTime(_vm.email.receivedDateTime))
    }
  }), _c('v-list-item-subtitle', {
    staticClass: "black--text text-caption"
  }, [_vm._v("   "), _vm.email.importance === 'important' ? _c('v-icon', {
    attrs: {
      "color": "error",
      "title": "Marked important."
    }
  }, [_vm._v("mdi-alert-decagram")]) : _vm._e(), _vm.email.hasAttachments ? _c('v-icon', {
    attrs: {
      "color": "primary",
      "title": "Email contains attachments."
    }
  }, [_vm._v("mdi-alert-decagram")]) : _vm._e()], 1)], 1), _c('v-list-item-action')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }