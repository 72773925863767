var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "primary secondary--text"
  }, [_vm._v(_vm._s(_vm.program.title) + " "), _c('v-spacer'), _c('v-icon', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.$store.dispatch('closeModal');
      }
    }
  }, [_vm._v("mdi-close")])], 1), _c('v-card-text', {
    staticClass: "d-flex flex-column align-start justify-start"
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center justify-space-between pt-2",
    attrs: {
      "width": "100%",
      "color": "transparent"
    }
  }, [_c('cardIconLabel', {
    attrs: {
      "href": _vm.catalogLink(),
      "icon": "open-in-new",
      "label": "CATALOG",
      "target": "_blank",
      "title": "Open in catalog."
    }
  }), _c('cardIconLabel', {
    attrs: {
      "icon": "clipboard-text-outline",
      "label": _vm.program.code,
      "title": "Copy program code to clipboard."
    },
    on: {
      "click": function click($event) {
        return _vm.$store.dispatch('clipboardWriteText', _vm.program.code);
      }
    }
  }), _c('cardIconLabel', {
    attrs: {
      "icon": "checkbox-blank-circle",
      "iconColor": _vm.getStatus(),
      "label": "STATUS",
      "title": _vm.program.status
    }
  })], 1), _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.listItems(), function (item) {
    return _c('v-list-item', {
      key: item.title
    }, [_c('v-list-item-content', [_c('v-list-item-title', {
      staticClass: "primary--text font-weight-regular"
    }, [_vm._v(_vm._s(item.title))]), _c('v-list-item-subtitle', {
      staticClass: "primary--text text-body-1 text-wrap"
    }, [_vm._v(_vm._s(item.subtitle))])], 1)], 1);
  }), _c('v-list-item', {
    key: "cats"
  }, [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "primary--text font-weight-regular"
  }, [_vm._v(" Categories ")]), _c('v-list-item-subtitle', _vm._l(this.category(this.program, 'array'), function (cat) {
    return _c('v-chip', {
      key: cat,
      staticClass: "mr-1",
      attrs: {
        "color": "primary",
        "label": "",
        "small": ""
      }
    }, [_vm._v(_vm._s(cat))]);
  }), 1)], 1)], 1), _c('v-list-item', {
    key: "notes"
  }, [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "primary--text font-weight-regular"
  }, [_vm._v("Notes")]), _c('v-list-item-subtitle', {
    staticClass: "primary--text text-body-1 text-wrap"
  }, [_vm._v(" " + _vm._s(_vm.program.notes || '-') + " ")])], 1)], 1)], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }