var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "primary--text d-flex align-center text-truncate",
    class: "".concat(_vm.isXSmall ? 'flex-column justify-space-around' : 'justify-center'),
    attrs: {
      "height": _vm.isXSmall ? '4.5rem' : '45px',
      "href": _vm.href,
      "flat": "",
      "target": "_blank",
      "tile": "",
      "width": _vm.isXSmall ? '5rem' : '100%',
      "hover": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click', {
          icon: _vm.icon,
          label: _vm.label
        });
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": !_vm.isXSmall,
      "color": _vm.iconColor || 'primary'
    }
  }, [_vm._v(_vm._s("mdi-".concat(_vm.icon)))]), _vm.isXSmall ? _c('br') : _vm._e(), _c('span', {
    staticClass: "ml-1 font-weight-bold",
    class: _vm.isXSmall ? 'caption' : ''
  }, [_vm._v(_vm._s(_vm.label))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }