<script>
export default {
  //   name: 'datetime-mixin',
  methods: {
    humanDate(timestamp = '') {
      const DateTime = new Date(timestamp)
      const Month = (DateTime.getMonth() + 1).toString().padStart(2, '0')
      const Day = DateTime.getDate().toString().padStart(2, '0')
      return Month + '/' + Day
    },
    humanTime(timestamp = '') {
      const DateTime = new Date(timestamp)
      const Hours = DateTime.getHours()
      const Minutes = DateTime.getMinutes().toString().padStart(2, '0')
      return Hours === 12
        ? `${Hours}:${Minutes}PM`
        : Hours > 12
        ? `${parseInt(Hours) - 12}:${Minutes}PM`
        : `${Hours}:${Minutes}AM`
    },
    humanDateTime(timestamp = '') {
      return `${this.humanDate(timestamp)}  ${this.humanTime(timestamp)}`
    }
  }
}
</script>
