<script>
import datetimeMixin from '@/mixins/datetime-mixin.vue'
export default {
  // name: 'program-mixin',
  mixins: [datetimeMixin],
  methods: {
    category(program = {}, format = 'string') {
      const subjects = Object.keys(program)
        .filter((k) => k.includes('subject') && program[k])
        .map((subject) => program[subject].trim())
        .sort((a, b) => a.localeCompare(b))
      const set = new Set(subjects)
      return format === 'string' ? set.join(', ') : set
    },
    humanTimeEnd(program = {}) {
      return this.humanTime(`${program.date} ${program.time_end}`)
    },
    humanTimeStart(program = {}) {
      return this.humanTime(`${program.date} ${program.time_start}`)
    }
  }
}
</script>
