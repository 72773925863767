var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    staticClass: "font-weight-bold",
    attrs: {
      "color": _vm.data.color || 'primary',
      "hover": "",
      "href": _vm.data.to,
      "text": "",
      "tile": "",
      "title": _vm.data.title,
      "target": "_blank"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": _vm.data.color || 'primary',
      "left": ""
    }
  }, [_vm._v(_vm._s("mdi-".concat(_vm.data.icon)))]), _vm._v(" " + _vm._s(_vm.data.text) + " ")], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }