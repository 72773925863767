<template>
  <v-card
    hover
    tile
    :title="data.title"
    width="100%"
    :href="data.to"
    target="_blank"
    @click="$emit('click')"
    ><v-card-text class="d-flex align-center primary--text font-weight-bold">
      <v-icon color="primary" left>{{ `mdi-${data.icon}` }}</v-icon>
      <v-sheet
        color="transparent"
        class="d-flex align-center flex-grow-1 primary--text font-weight-bold"
      >
        {{ data.text }}
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'card-link-horizontal',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
