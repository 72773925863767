var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "min-height": "119",
      "scrollable": "",
      "tile": ""
    }
  }, [_c('v-card-title', {
    staticClass: "primary--text"
  }, [_c('v-icon', {
    attrs: {
      "color": "primary",
      "left": ""
    }
  }, [_vm._v("mdi-email-multiple")]), _vm._v(" MESSAGES ")], 1), _c('v-card-text', {
    staticClass: "scrollable-card primary--text"
  }, [_c('communications-card', {
    attrs: {
      "messages": _vm.messages
    }
  }), _c('email-card', {
    staticClass: "mt-2",
    attrs: {
      "emails": _vm.emails
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }