var render = function () {
  var _vm$program, _vm$program$location;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.$emit('click', _vm.program);
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-subtitle-1 primary--text font-weight-black pb-1"
  }, [_vm._v(_vm._s(_vm.program.title))]), _c('v-list-item-title', {
    staticClass: "text-body-2 font-weight-bold",
    domProps: {
      "textContent": _vm._s("".concat(_vm.humanTime("".concat(_vm.program.date, " ").concat(_vm.program.time_start)), " - ").concat(_vm.humanTime("".concat(_vm.program.date, " ").concat(_vm.program.time_end))))
    }
  }), _c('v-list-item-title', {
    staticClass: "text-body-2 primary--text",
    domProps: {
      "textContent": _vm._s(((_vm$program = _vm.program) === null || _vm$program === void 0 ? void 0 : (_vm$program$location = _vm$program.location) === null || _vm$program$location === void 0 ? void 0 : _vm$program$location.replace('EI-', '')) || 'Location NA')
    }
  }), _c('v-list-item-subtitle', {
    staticClass: "black--text text-caption",
    domProps: {
      "innerHTML": _vm._s(_vm.program.description || 'Please call for info.')
    }
  })], 1), _c('v-list-item-action', [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-chevron-down")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }