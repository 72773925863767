<script>
export default {
  name: 'card-link-vertical',
  props: {
    cardProps: {
      type: Object,
      default: () => ({})
    },
    href: {
      type: String,
      default: () => ''
    },
    icon: {
      type: String,
      default: () => ''
    },
    iconProps: {
      type: Object,
      default: () => ({})
    },
    isVertical: {
      type: Boolean,
      default: () => true
    },
    text: {
      type: String,
      default: () => ''
    }
  }
}
</script>
<template>
  <v-card height="10rem" hover :href="href" target="_blank" tile width="10rem">
    <v-card-text
      style="height: 100%"
      class="d-flex flex-column align-center justify-space-around"
      :class="{ 'flex-row': !isVertical }"
    >
      <v-icon v-text="`mdi-${icon}`" color="primary" large></v-icon>
      <p
        v-text="text"
        class="mb-0 primary--text font-weight-bold text-center"
      ></p>
    </v-card-text>
  </v-card>
</template>
<style lang="scss" scoped></style>
