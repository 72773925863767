<script>
import programMixin from '@/mixins/program-mixin'

import cardIconLabel from '@/components/__card-icon-label.vue'
export default {
  name: 'program-card',
  props: {
    program: {
      type: Object,
      required: true
    },
    withActions: {
      type: Boolean,
      default: () => false
    }
  },
  components: { cardIconLabel },
  mixins: [programMixin],
  // data: () => ({
  //   contentLoading: true,
  //   listItemExpanded: null,
  //   programs: null,
  //   programsUpcoming: [],
  //   showProgramModal: false,
  //   programModalProgram: {}
  // }),
  methods: {
    catalogLink() {
      const baseUrl =
        'https://search.livebrary.com/search~S20/?searchtype=Y&searcharg=[PROGRAM_CODE]&searchscope=20'
      const href = baseUrl.replaceAll('[PROGRAM_CODE]', this.program.code)
      return href
    },

    displayProgramModal(e) {
      console.log('displayProgramModal')
      console.log(e)
      this.programModalProgram = e
      this.showProgramModal = true
    },
    getStatus() {
      let status = 'success'
      const statusStr = this.program?.status_str?.toLowerCase() || ''
      if (
        statusStr.includes('closed') ||
        statusStr.includes('full') ||
        statusStr.includes('wait')
      )
        status = 'error'
      return status
    },
    onProgramItem(item) {
      console.log(item)
    },
    listItems() {
      return [
        {
          subtitle: this.humanDate(
            `${this.program.date} ${this.program.time_start}`
          ),
          title: 'Date' || 'N/A'
        },
        {
          subtitle: this.program.time_string || 'N/A',
          title: 'Time'
        },
        {
          subtitle: this.program.location || 'N/A',
          title: 'Location'
        },
        {
          subtitle: this.program.instructor || 'N/A',
          title: 'Instructor'
        },
        {
          subtitle:
            this.program.description || 'No further description provided',
          title: 'Description'
        }
        // {
        //   subtitle: this.program.notes || '-',
        //   title: 'Notes'
        // }
      ]
    }
  }
}
</script>
<template>
  <v-card>
    <v-card-title class="primary secondary--text"
      >{{ program.title }}
      <v-spacer></v-spacer>
      <v-icon color="secondary" @click="$store.dispatch('closeModal')"
        >mdi-close</v-icon
      >
    </v-card-title>
    <v-card-text class="d-flex flex-column align-start justify-start">
      <v-sheet
        width="100%"
        color="transparent"
        class="d-flex align-center justify-space-between pt-2"
      >
        <!-- :class="$vuetify.breakpoint.xs && 'flex-column'" -->
        <cardIconLabel
          :href="catalogLink()"
          icon="open-in-new"
          label="CATALOG"
          target="_blank"
          title="Open in catalog."
        ></cardIconLabel>
        <cardIconLabel
          icon="clipboard-text-outline"
          :label="program.code"
          title="Copy program code to clipboard."
          @click="$store.dispatch('clipboardWriteText', program.code)"
        ></cardIconLabel>
        <cardIconLabel
          icon="checkbox-blank-circle"
          :iconColor="getStatus()"
          label="STATUS"
          :title="program.status"
        ></cardIconLabel>
        <!-- <v-btn
          color="primary"
          :href="catalogLink()"
          target="_blank"
          text
          tile
          title="Open in catalog."
          >CATALOG <v-icon color="primary" right>mdi-open-in-new</v-icon></v-btn
        > -->
        <!-- <v-btn
          color="primary"
          text
          tile
          title="Copy program code to clipboard."
          @click="$store.dispatch('clipboardWriteText', program.code)"
          >{{ program.code }}
          <v-icon color="primary" right>mdi-clipboard-text-outline</v-icon>
        </v-btn> -->
        <!-- <v-btn color="primary" text tile :title="program.status"
          >STATUS
          <v-icon :color="getStatus()" right
            >mdi-checkbox-blank-circle</v-icon
          ></v-btn
        > -->
      </v-sheet>
      <v-list dense>
        <v-list-item v-for="item in listItems()" :key="item.title">
          <!-- <v-list-item-icon>
          <v-icon color="indigo">
            mdi-phone
          </v-icon>
        </v-list-item-icon> -->

          <v-list-item-content>
            <v-list-item-title class="primary--text font-weight-regular">{{
              item.title
            }}</v-list-item-title>
            <v-list-item-subtitle class="primary--text text-body-1 text-wrap">{{
              item.subtitle
            }}</v-list-item-subtitle>
          </v-list-item-content>

          <!-- <v-list-item-icon>
          <v-icon>mdi-message-text</v-icon>
        </v-list-item-icon> -->
        </v-list-item>
        <v-list-item key="cats">
          <v-list-item-content>
            <v-list-item-title class="primary--text font-weight-regular">
              Categories
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-chip
                v-for="cat in this.category(this.program, 'array')"
                :key="cat"
                color="primary"
                label
                small
                class="mr-1"
                >{{ cat }}</v-chip
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item key="notes">
          <v-list-item-content>
            <v-list-item-title class="primary--text font-weight-regular"
              >Notes</v-list-item-title
            >
            <v-list-item-subtitle class="primary--text text-body-1 text-wrap">
              {{ program.notes || '-' }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
    <!-- <v-card-actions v-if="withActions">
      <v-spacer></v-spacer>
      <v-btn color="primary" text tile @click="$store.dispatch('closeModal')"
        >CLOSE</v-btn
      >
    </v-card-actions> -->
  </v-card>
</template>
