var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-badge', {
    staticClass: "badge",
    attrs: {
      "color": "accent",
      "value": _vm.value
    },
    scopedSlots: _vm._u([{
      key: "badge",
      fn: function fn() {
        return [_c('span', {
          staticClass: "secondary--text font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.badgeContent) + " ")])];
      },
      proxy: true
    }])
  }, [_vm._t("child")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }