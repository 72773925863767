<template>
  <v-list-item @click="$emit('click', program)">
    <v-list-item-content>
      <v-list-item-title
        class="text-subtitle-1 primary--text font-weight-black pb-1"
        >{{ program.title }}</v-list-item-title
      >
      <v-list-item-title
        v-text="
          `${humanTime(`${program.date} ${program.time_start}`)} - ${humanTime(
            `${program.date} ${program.time_end}`
          )}`
        "
        class="text-body-2 font-weight-bold"
      ></v-list-item-title>
      <v-list-item-title
        v-text="program?.location?.replace('EI-', '') || 'Location NA'"
        class="text-body-2 primary--text"
      ></v-list-item-title>
      <v-list-item-subtitle
        v-html="program.description || 'Please call for info.'"
        class="black--text text-caption"
      ></v-list-item-subtitle>
      <!-- <v-icon small color="primary" class="categoryIcon">fas fa-fish</v-icon> -->
    </v-list-item-content>
    <v-list-item-action>
      <v-icon color="primary">mdi-chevron-down</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import programMixin from '@/mixins/program-mixin.vue'
//datetimeMixin inherited from programMixin
export default {
  name: 'program-list-item',
  props: {
    program: {
      type: Object,
      required: true
    }
  },
  mixins: [programMixin]
}
</script>
<style scoped>
.bgcolor {
  background-color: #ffffffc7;
}
.categoryIcon {
  position: absolute;
  top: 2px;
  right: 5px;
}
p {
  margin-bottom: 0px;
}
</style>
