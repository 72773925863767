<template>
  <v-list-item :href="email.webLink" rel="noopener noreferrer" target="_blank">
    <v-list-item-content>
      <v-list-item-title
        :title="email?.from?.emailAddress?.address"
        v-text="email?.from?.emailAddress?.name"
        class="text-subtitle-1 primary--text font-weight-black pb-1"
      ></v-list-item-title>
      <v-list-item-title class="text-body-2 primary--text">{{
        email.subject
      }}</v-list-item-title>
      <v-list-item-title
        v-text="humanDateTime(email.receivedDateTime)"
        class="text-body-2 font-weight-bold"
      ></v-list-item-title>
      <v-list-item-subtitle class="black--text text-caption">
        &nbsp;
        <v-icon
          v-if="email.importance === 'important'"
          color="error"
          title="Marked important."
          >mdi-alert-decagram</v-icon
        >
        <v-icon
          v-if="email.hasAttachments"
          color="primary"
          title="Email contains attachments."
          >mdi-alert-decagram</v-icon
        >
      </v-list-item-subtitle>
      <!-- <v-icon small color="primary" class="categoryIcon">fas fa-fish</v-icon> -->
    </v-list-item-content>
    <v-list-item-action>
      <!-- <v-icon color="primary">mdi-open-in-new</v-icon> -->
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import programMixin from '@/mixins/program-mixin.vue'
//datetimeMixin inherited from programMixin
export default {
  name: 'email-list-item',
  props: {
    email: {
      type: Object,
      required: true
    }
  },
  mixins: [programMixin]
}
</script>
<style scoped>
.bgcolor {
  background-color: #ffffffc7;
}
.categoryIcon {
  position: absolute;
  top: 2px;
  right: 5px;
}
p {
  margin-bottom: 0px;
}
</style>
