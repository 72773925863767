var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "height": "10rem",
      "hover": "",
      "href": _vm.href,
      "target": "_blank",
      "tile": "",
      "width": "10rem"
    }
  }, [_c('v-card-text', {
    staticClass: "d-flex flex-column align-center justify-space-around",
    class: {
      'flex-row': !_vm.isVertical
    },
    staticStyle: {
      "height": "100%"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary",
      "large": ""
    },
    domProps: {
      "textContent": _vm._s("mdi-".concat(_vm.icon))
    }
  }), _c('p', {
    staticClass: "mb-0 primary--text font-weight-bold text-center",
    domProps: {
      "textContent": _vm._s(_vm.text)
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }