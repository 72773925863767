<script>
import badge from '@/components/__badge.vue'
import btnIconLabel from '@/components/btn-icon-label.vue'
import cardDailyInfo from '@/components/daily-info-card.vue'
import cardLinkVertical from '@/components/card-link-vertical.vue'
import cardLinkHorizontal from '@/components/card-link-horizontal.vue'
import messagesCard from '@/components/messages-card.vue'
import programCard from '@/components/program-card.vue'
import programList from '@/components/program-list.vue'
// import programListItem from '@/components/program-list-item.vue'
import weather from '@/components/weather.vue'

// TODO: temp data pull from api on mount
import apps from '@/data/tempApps.js'
import messages from '@/data/tempMessages.js'
import programs from '@/data/tempPrograms.js'

import api from '@/utils/api'

import { mapState } from 'vuex'
export default {
  name: 'home',
  components: {
    badge,
    btnIconLabel,
    cardDailyInfo,
    cardLinkHorizontal,
    cardLinkVertical,
    messagesCard,
    programCard,
    programList,
    weather
  },
  data: () => ({
    appList: apps,
    error: null,
    isLoggedIn: false,
    messageList: messages,
    password: null,
    programList: programs,
    quickLinks: [
      {
        icon: 'calendar-month',
        text: 'CALENDAR',
        title: 'https://search.livebrary.com//iii/calendar/month/C&R=20',
        to: 'https://search.livebrary.com//iii/calendar/month/C&R=20'
      },
      {
        icon: 'library',
        text: 'HOMEPAGE',
        title: 'https://www.eipl.org',
        to: 'https://www.eipl.org'
      },
      {
        icon: 'email',
        text: 'E-MAIL',
        title: 'https://outlook.office.com',
        to: 'https://outlook.office.com'
      },
      {
        icon: 'credit-card-clock',
        rotate: 270,
        text: 'TIME',
        title: 'https://accudata.myisolved.com/UserLogin.aspx',
        to: 'https://accudata.myisolved.com/UserLogin.aspx'
      }
      // {
      //   icon: 'clock',
      //   text: 'TIME CARD',
      //   title: 'https://accudata.myisolved.com/cloudservice/home/time',
      //   to: 'https://accudata.myisolved.com/cloudservice/home/time'
      // },
    ],
    showPassword: false,
    searchShow: false,
    submitted: false,
    username: null
    // weather: null
  }),
  computed: {
    ...mapState({
      app: (state) => state.app,
      modal: (state) => state.modal,
      user: (state) => state.user,
      weatherForecastShow: (state) => state.weatherForecastShow
    }),
    btnState() {
      if (this.submitted) return { loading: true }
      if (this.username === null || this.password === null)
        return { disabled: true }
      return {}
    }
  },
  methods: {
    onLogin() {
      //todo: route depending on role ?
      const params = new URLSearchParams(window.location.href)
      const dest = params.get('dest') || null
      if (dest) window.location.replace(decodeURI(dest))
    },
    onMessageDismiss(id = null) {
      if (!id) return
      //TODO: mark read on backend
      this.messageList = this.messageList.filter((m) => m.id !== id)
    },
    onProgramClick(el) {
      console.log('onProgramItem')
      console.log(el)
      console.log(el.target)
      console.log(el.target.scrollTop)
      console.log(el.target.srcElement)
      const offset = el.target.offsetTop
      console.log(offset)
      console.log(this.$refs.programList)
      this.$refs.programList.scrollTop = offset
      // if (el.target) el.target.scrollIntoView(true, { behavior: 'smooth' })
      // if (el.target) this.$vuetify.goTo(el.target)
    },
    programCardTextStyle() {
      const style = {
        height: '20rem'
      }
      //EACH LIST TILE IS ~102px
      const tileCount =
        this.programList.length > 0 ? this.programList.length : 1
      style.height = tileCount * 119 + 'px'
      return style
    },
    submitLogin() {
      this.error = null
      //await login
      // const result = ...
      //if success
      //this.isLoggedIn = true
      //onLogin()
      //return
      this.error = 'Invalid username or password.'
    }
  },
  async mounted() {
    console.log('dashboard mounted: then->get Data')
    console.log(this.$vuetify)
    // let nav = []
    let programs = []
    let weather = {}

    try {
      const resolved = await Promise.all([
        api.get(''),
        api.get('programs'),
        api.get('weather')
      ])
      console.log(resolved)
      // nav = resolved[0]?.data?.nav
      programs = resolved[1].sort((a, b) => a.time_start - b.time_end)
      weather = resolved[2]
      // console.log(nav)
      console.log(weather)
    } catch (error) {
      console.log(error)
    } finally {
      this.programList = programs
      // this.$store.dispatch('setAppData', { ...this.app, nav })
      this.$store.dispatch('setWeather', weather)
    }
  }
}
</script>
<template>
  <v-row no-gutters>
    <v-col cols="12" sm="6" md="6" class="pt-0">
      <card-daily-info></card-daily-info>
    </v-col>

    <!-- QUICK LINKS -->
    <v-col cols="12" md="6" class="pt-0">
      <!-- <v-col cols="12" sm="3" md="6" class="pt-0"> -->
      <v-card flat tile>
        <v-card-text
          v-if="$vuetify.breakpoint.mdAndUp"
          class="d-flex align-start justify-end"
        >
          <!-- :class="{
            'flex-column': $vuetify.breakpoint.sm,
            'justify-space-around': $vuetify.breakpoint.xs
          }" -->
          <btn-icon-label
            :data="qlink"
            v-for="qlink in quickLinks"
            :key="qlink.icon"
            class="flex-shrink-1"
          ></btn-icon-label>
        </v-card-text>
        <v-card-text v-else class="pt-0">
          <card-link-horizontal
            :data="qlink"
            v-for="qlink in quickLinks"
            :key="qlink.icon"
            class="flex-shrink-1 my-1"
          ></card-link-horizontal>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" v-if="weatherForecastShow">
      <v-slide-y-transition>
        <v-card flat min-height="119" scrollable tile>
          <v-card-title class="primary--text">
            <v-icon color="primary" left>mdi-weather-partly-snowy-rainy</v-icon
            >WEATHER</v-card-title
          >
          <v-card-text class="scrollable-card primary--text">
            <weather></weather>
          </v-card-text>
        </v-card>
      </v-slide-y-transition>
    </v-col>

    <v-col cols="12" class="my-4">
      <messages-card
        :emails="user?.azure?.unreadEmail || []"
        :messages="messageList"
      ></messages-card>
    </v-col>
    <v-col cols="12">
      <v-card flat scrollable tile color="primary">
        <v-card-title class="secondary--text">
          <badge
            :value="programList.length > 0"
            :badgeContent="programList.length"
          >
            <template v-slot:child> TODAY'S PROGRAMS </template>
          </badge>
        </v-card-title>
        <v-card-text
          ref="programList"
          class="scrollable-card secondary"
          :style="programCardTextStyle()"
        >
          <program-list
            :programs="programList"
            @click="onProgramClick"
          ></program-list>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card flat tile>
        <v-card-title class="primary--text">
          <v-icon color="primary" left>mdi-tools</v-icon>STAFF
          TOOLS</v-card-title
        >
        <v-card-text
          v-if="$vuetify.breakpoint.smAndUp"
          class="d-flex flex-wrap align-start"
          :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-start'"
        >
          <card-link-vertical
            v-for="app in appList"
            :key="app.label"
            v-bind="app"
            class="ma-2"
          ></card-link-vertical>
        </v-card-text>
        <v-card-text v-else>
          <card-link-horizontal
            :data="app"
            v-for="app in appList"
            :key="app.icon"
            class="flex-shrink-1 my-1"
          ></card-link-horizontal>
        </v-card-text>
      </v-card>
    </v-col>

    <v-dialog
      :value="modal.value"
      max-width="40rem"
      width="100vw"
      @input="$store.dispatch('closeModal')"
    >
      <component :is="modal.component" v-bind="modal.data"></component>
    </v-dialog>
  </v-row>
</template>
<style lang="scss" scoped>
.scrollable-card {
  max-height: 35rem;
  overflow-y: auto;
}
</style>
