var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "d-flex align-start justify-start",
    attrs: {
      "flat": "",
      "tile": "",
      "width": "100%"
    }
  }, [_c('v-card-text', {
    staticClass: "d-flex align-start justify-start"
  }, [_c('v-sheet', {
    staticClass: "d-flex flex-column align-start justify-start primary--text"
  }, [_c('p', {
    staticClass: "text-h4 text-no-wrap mb-0"
  }, [_vm._v(_vm._s(_vm.today))]), _c('v-sheet', {
    staticClass: "d-flex align-center primary--text",
    attrs: {
      "color": "transprant"
    }
  }, [_c('p', {
    staticClass: "text-h6 mb-0"
  }, [_vm._v(_vm._s(_vm.now))]), _c('weather-minimal')], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }