<script>
import weatherMinimal from '@/components/weather-minimal.vue'
export default {
  name: 'daily-info-card',
  components: { weatherMinimal },
  data: () => ({
    interval: null,
    now: '',
    today: ''
  }),
  methods: {
    getNow() {
      const now = new Date()
      const options = {
        timeStyle: 'short'
      }
      return now.toLocaleTimeString('en', options)
    },
    getToday() {
      const now = new Date()
      const options = {
        weekday: 'long',
        // year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      // console.log(now.toLocaleDateString('en-US', options))
      return now.toLocaleDateString('en-US', options)
    }
  },
  mounted() {
    this.today = this.getToday()
    this.now = this.getNow()
    setInterval(() => {
      this.now = this.getNow()
    }, 1000 * 60)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<template>
  <v-card flat tile width="100%" class="d-flex align-start justify-start">
    <v-card-text class="d-flex align-start justify-start">
      <v-sheet
        class="d-flex flex-column align-start justify-start primary--text"
      >
        <p class="text-h4 text-no-wrap mb-0">{{ today }}</p>
        <v-sheet color="transprant" class="d-flex align-center primary--text">
          <p class="text-h6 mb-0">{{ now }}</p>
          <weather-minimal></weather-minimal>
        </v-sheet>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped></style>
