<script>
// import tempData from '@/data/tempWeather.js'

export default {
  // name: 'weather-mixin',
  data: () => ({
    alerts: null,
    // current: {},
    error: null,
    // forecast: [],
    isLoading: false
  }),
  computed: {
    current() {
      return this.formatWeatherData(this.weather.current) || {}
    },
    forecast() {
      return (
        this?.weather?.daily?.map((day) => this.formatWeatherData(day)) || []
      )
    },
    weather() {
      return this.$store.state.weather
    }
  },
  methods: {
    degToCompass(deg) {
      const val = Math.floor(deg / 22.5 + 0.5)
      const directions = [
        'N',
        'NNE',
        'NE',
        'ENE',
        'E',
        'ESE',
        'SE',
        'SSE',
        'S',
        'SSW',
        'SW',
        'WSW',
        'W',
        'WNW',
        'NW',
        'NNW'
      ]
      return directions[val % 16]
    },
    getDay(
      date = new Date().getTime() / 1000,
      dateOptions = { weekday: 'short' }
    ) {
      return new Intl.DateTimeFormat('en-US', dateOptions).format(
        new Date(date * 1000)
      )
    },
    getDetails(raw) {
      console.log(raw)
      const mappings = [
        { key: 'clouds', text: 'Cloud coverage', append: '' },
        { key: 'dew_point', text: 'Dew point', append: '' },
        // feels like?
        { key: 'humidity', text: 'Chance of percipitation', append: '%' },
        { key: 'pop', text: 'Chance of percipitation', append: '%' }, //CONVERT %
        { key: 'uvi', text: 'UV index' },
        { key: 'wind_speed', text: 'Wind', append: 'mph' }
      ]
      return mappings
    },
    getIcon(iconName = '') {
      if (!iconName) return '' //TODO: SET PLACE HOLDER
      return `https://openweathermap.org/img/wn/${iconName}@2x.png`
    },
    getWeather() {
      //TODO: Call API
      // return tempData
    },
    formatCurrentWeather(raw) {
      const currentWeather = { ...raw }
      const weather = raw.weather[0]
      currentWeather.dayOfWeek = 'Now'
      currentWeather.description = weather.description
      currentWeather.icon = this.getIcon(weather.icon)
      return currentWeather
    },
    formatWeatherData(raw) {
      const weatherData = { ...raw }
      const weather = weatherData.weather[0]
      const today = this.getDay()
      const dataDay = this.getDay(raw.dt)
      weatherData.dayOfWeek = dataDay === today ? 'Today' : dataDay
      weatherData.description = weather.description
      weatherData.shortForecast = weather.main
      weatherData.icon = this.getIcon(weather.icon)
      weatherData.wind_compass = this.degToCompass(weatherData.wind_deg)

      return weatherData
    }
  },
  mounted() {
    // const weather = this.getWeather()
    // console.log(weather)
    // const { alerts, current, daily } = weather
    // this.alerts = alerts //this.formatAlerts ?
    // this.current = this.formatWeatherData(current)
    // this.forecast = daily.map((day) => this.formatWeatherData(day))
  }
}
</script>
