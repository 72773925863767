<script>
import weatherForecastDay from '@/components/weather-forecast-day.vue'
import datetimeMixin from '@/mixins/datetime-mixin.vue'
import weatherMixin from '@/mixins/weather-mixin.vue'
//import api from '@/utils/api.js'
export default {
  name: 'weather',
  components: {
    weatherForecastDay
  },
  mixins: [datetimeMixin, weatherMixin],
  computed: {
    forecastData() {
      return this.forecast.map((forecast) => this.formatWeatherData(forecast))
    }
  },
  methods: {}
}
</script>
<template>
  <v-card flat tile>
    <v-card-text class="d-flex align-center justify-space-evenly pt-0">
      <!-- <v-card width="300" class="mx-1">
          <v-card-text class="d-flex align-center justify-space-between pa-1">
            <p class="primary--text font-weight-bold text-h6 ma-0">
                {{ current.dayOfWeek }}
              </p> 
            <v-img
              :src="current.icon"
              contain
              max-height="85"
              max-width="85"
            ></v-img>

            <v-sheet class="d-flex flex-column align-start justify-start">
              <p class="primary--text text-h5 font-weight-bold ma-0">
                {{ Math.round(current.temp) }}º
              </p>
              <p class="primary--text text-body-1 font-weight-bold ma-0 mt-n2">
                {{ current.description }}
              </p>
            </v-sheet>
            <v-sheet
              height="100%"
              class="d-flex flex-column align-start justify-start"
            >
              <p class="primary--text font-weight-bold ma-0">
                {{ current.dayOfWeek }}
              </p>
              <p class="primary--text font-weight-bold ma-0">
                {{ current.dayOfWeek }}
              </p>
              <p class="primary--text font-weight-bold ma-0">
                {{ current.dayOfWeek }}
              </p>
              <p class="primary--text font-weight-bold ma-0">
                {{ current.dayOfWeek }}
              </p>
            </v-sheet>
            <p class="primary--text font-weight-bold ma-0">
              {{ current.shortForecast }}
            </p>
          </v-card-text>
        </v-card> -->
      <v-sheet
        width="100%"
        class="weather-sheet d-flex align-center justify-space-between flex-grow-1 py-4"
      >
        <weather-forecast-day
          v-for="forecast in forecastData"
          :key="forecast.dt"
          :forecast="forecast"
          height="150"
          width="150"
          class="mx-1"
        ></weather-forecast-day>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.weather-sheet {
  width: 100%;
  overflow-x: scroll;
}
</style>
