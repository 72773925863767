const programs = [
  {
    id: '1555',
    code: 'EIJ591',
    title: 'American Sign Language (Grades 3 - 5) ',
    desc: 'Learn beginning American Sign Language with Professional Instructor, Jennie.  Siblings may attend the same session.',
    notes:
      'Please indicate child\'s grade in the "Note to Instructor" to ensure your child\'s place in the program.  Thank you.  No tables, 20 chairs in a U shape facing the screen; please have laptop and projector as well as white board or chalkboard. One table and 1 chair at the front of the room.',
    time_start: '2019-07-10 16:00:00',
    time_end: '2019-07-10 17:00:00',
    status: 'Closed',
    subject1: 'Childrens',
    subject2: 'School Age ',
    DateTimeStr: 'Wednesday, Jul 17 2019 4:00pm-5:00pm',
    location: "EI-Children's Mtg Room",
    instructor: 'Jennie Sardone',
    statusStr: 'Closed',
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1554',
    code: 'EIJ590',
    title: 'American Sign Language (Grades K - 2) ',
    desc: 'Learn beginning American Sign Language with Professional Instructor, Jennie.  Siblings may attend the same session.',
    notes:
      'Please indicate child\'s grade in the "Note to Instructor" to ensure your child\'s place in the program.  Thank you.  No tables, 20 chairs in a U shape facing the screen; please have laptop and projector as well as white board or chalkboard. One table and 1 chair at the front of the room.',
    time_start: '2019-07-10 15:00:00',
    time_end: '2019-07-10 16:00:00',
    status: 'Closed',
    subject1: 'Childrens',
    subject2: 'School Age ',
    DateTimeStr: 'Wednesday, Jul 17 2019 3:00pm-4:00pm',
    location: "EI-Children's Mtg Room",
    instructor: 'Jennie Sardone',
    statusStr: 'Closed',
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1552',
    code: 'EIA154',
    title: 'Proctoring ',
    desc: 'Test Proctoring for East Islip residents',
    notes: null,
    time_start: '2019-07-10 14:00:00',
    time_end: '2019-07-10 15:15:00',
    status: 'No registration required',
    subject1: 'Adult ',
    subject2: 'Adult ',
    DateTimeStr: null,
    location: null,
    instructor: null,
    statusStr: null,
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1553',
    code: 'EIA443',
    title: 'Wednesday Film Series ',
    desc: 'This eclectic series will include films based on a monthly theme.',
    notes: 'Setup:  N9',
    time_start: '2019-07-10 14:15:00',
    time_end: '2019-07-10 16:02:00',
    status: 'No registration required',
    subject1: 'Adult ',
    subject2: null,
    DateTimeStr: 'Wednesday, Aug 28 2019 2:15pm-3:23pm',
    location: 'EI-North Meeting Room',
    instructor: 'Nancy Drew - Reporter (1939)',
    statusStr: 'No Registration Required',
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1551',
    code: 'EIA285',
    title: 'Sewing Circle',
    desc: null,
    notes: 'Setup: 2 Tables squared & 10 Chairs around and a powerstrip',
    time_start: '2019-07-10 11:30:00',
    time_end: '2019-07-10 13:30:00',
    status: 'Closed',
    subject1: 'Adult ',
    subject2: null,
    DateTimeStr: 'Wednesday, Jul 17 2019 11:30am-1:30pm',
    location: 'EI-South Meeting Room',
    instructor: '',
    statusStr: 'Closed',
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1550',
    code: 'EIA399',
    title: 'Yoga ',
    desc: 'If you have always wanted to experience the benefits of yoga--improvement in flexibility, strength, posture and more.',
    notes:
      'Please register for this program at the Circulation Desk. Room setup: S4.',
    time_start: '2019-07-10 10:00:00',
    time_end: '2019-07-10 11:00:00',
    status: 'Please Contact the library to Register',
    subject1: 'Adult ',
    subject2: 'Exercise',
    DateTimeStr: 'Wednesday, Jul 17 2019 10:00am-11:00am',
    location: 'EI-South Meeting Room',
    instructor: 'Adrienne Lebron',
    statusStr: 'Please Contact the Library to Register',
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1549',
    code: 'EIA358',
    title: 'Bus Trip: Tour of North Fork ',
    desc: "Our day will begin in Riverhead, where we will start a tour along the way of the Rt. 25 wine trail as well as Sound Avenue.  Our first stop will be Love Lane in Mattituck for some time on your own to walk around.  Next, we will stop at Castello Di Borghese, Long Island\u2019s founding vineyard.  Enjoy a tour of their vineyard as well as a formal sit-down wine tasting paired with artisanal cheeses. Following Castello Di Borghese, we will stop for a late luncheon at the Townsend Inn located in Greenport.  After lunch, it's more North Fork touring (with our guide) and we\u2019ll end the day at Briermere Farms, known for their pies.",
    notes:
      'Register at Circulation Desk with check or money order for $115 payable to Islip Public Library. Bus departs East Islip Library at 8:00Am and returns approximately at 6:30 PM.',
    time_start: '2019-07-10 08:00:00',
    time_end: '2019-07-10 18:30:00',
    status: 'See Note for Registration Info',
    subject1: 'Adult ',
    subject2: 'Trips',
    DateTimeStr: null,
    location: null,
    instructor: null,
    statusStr: null,
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1548',
    code: 'EIA460',
    title: 'Computing I - Introduction to Computing ',
    desc: 'Basic Windows functions are covered in this class. Learn the desktop, keyboard functions, opening and closing programs, drives, status bar, access MS word, save files, using a flashdrive to save documents and printing.',
    notes: null,
    time_start: '2019-07-09 20:00:00',
    time_end: '2019-07-09 21:00:00',
    status: 'Closed',
    subject1: 'Adult ',
    subject2: 'Computer ',
    DateTimeStr: null,
    location: null,
    instructor: null,
    statusStr: null,
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1547',
    code: 'EIA164',
    title: 'National Ovarian Cancer Coalition ',
    desc: null,
    notes: 'Setup: N2',
    time_start: '2019-07-09 19:00:00',
    time_end: '2019-07-09 21:00:00',
    status: 'No registration required',
    subject1: 'Adult ',
    subject2: 'Health',
    DateTimeStr: 'Tuesday, Sep 10 2019 7:00pm-9:00pm',
    location: 'EI-North Meeting Room',
    instructor: 'Sally Gabriel',
    statusStr: 'No Registration Required',
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1545',
    code: 'EIJ577',
    title: 'Fingerprinting (Grades 4 - 5) ',
    desc: 'Participants will learn techniques used by law enforcement in order to dust, lift and analyze latent fingerprints on various surfaces.',
    notes:
      'Please indicate child\'s grade in the "Note to Instructor" to ensure your child\'s place in the program.  Thank you.  Set-up tables and chairs for 20, covered with plastic and drop cloths underneath, 1 instructors table at front of room',
    time_start: '2019-07-09 17:30:00',
    time_end: '2019-07-09 18:45:00',
    status: 'Closed',
    subject1: 'Childrens',
    subject2: 'School Age ',
    DateTimeStr: null,
    location: null,
    instructor: null,
    statusStr: null,
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1546',
    code: 'EIJ578',
    title: 'Read to the Dogs (Readers in grades 1 - 6) ',
    desc: 'Students practice their reading skills and gain confidence by reading aloud to a trained Therapy Dog.',
    notes:
      "Student readers can sign up for a 20-minute session when they come to the Children's Desk to register.",
    time_start: '2019-07-09 18:30:00',
    time_end: '2019-07-09 20:10:00',
    status: 'See Note for Registration Info',
    subject1: 'Childrens',
    subject2: 'School Age ',
    DateTimeStr: 'Tuesday, Aug 06 2019 6:30pm-8:10pm',
    location: 'EI-East Islip',
    instructor: 'Therapy Dogs International',
    statusStr: 'See Note for Registration Info',
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1544',
    code: 'EIY718',
    title: 'Acting Workshop - Young Adult ',
    desc: 'Calling all actors!  Attend these dramatic workshops to learn the art of improvisation, hone your acting skills, perfect your monologue delivery, and even share your ability to write!  For those entering grades 6-12.',
    notes:
      'Please note your grade in the "Note to Instructor" field to ensure your place in the program.  Setup: N10 with 15 chairs, STG, MIC, 1 card table',
    time_start: '2019-07-09 17:00:00',
    time_end: '2019-07-09 18:30:00',
    status: 'Closed',
    subject1: 'Young Adult ',
    subject2: 'Theatre',
    DateTimeStr: 'Monday, Jul 15 2019 5:00pm-6:30pm',
    location: 'EI-North Meeting Room',
    instructor: 'Dr. Smith',
    statusStr: 'Closed',
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1543',
    code: 'EIJ576',
    title: 'Fingerprinting (Grades 2 - 3) ',
    desc: 'Participants will learn techniques used by law enforcement in order to dust, lift and analyze latent fingerprints on various surfaces.',
    notes:
      'Please indicate child\'s grade in the "Note to Instructor" to ensure your child\'s place in the program.  Thank you.  Set-up tables and chairs for 20, covered with plastic and drop cloths underneath, 1 instructors table at front of room',
    time_start: '2019-07-09 16:00:00',
    time_end: '2019-07-09 17:15:00',
    status: 'Closed',
    subject1: 'Childrens',
    subject2: 'School Age ',
    DateTimeStr: null,
    location: null,
    instructor: null,
    statusStr: null,
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1541',
    code: 'EIA252',
    title: 'Programmer Setup ',
    desc: 'This record is to be used to block periods of time for programmer setup.',
    notes: null,
    time_start: '2019-07-09 12:00:00',
    time_end: '2019-07-09 15:00:00',
    status: 'No registration required',
    subject1: 'Adult ',
    subject2: null,
    DateTimeStr: 'Thursday, Jul 25 2019 6:00pm-7:00pm',
    location: 'EI-North Meeting Room',
    instructor: 'Setup for Escape the Library',
    statusStr: 'No Registration Required',
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1542',
    code: 'EIA125',
    title: 'Senior Fitness ',
    desc: 'While seated in a chair, participants engage in weight bearing activities using dumbbells and resistance bands.  Emphasis is placed on increasing muscular strength, flexibility and functional skills for daily living.  Participants are encouraged to supply their own dumbbells (3 or 5 lb weights or 2 soup cans).  Bands will be supplied.  Please consult with a physician before beginning any new exercise program.',
    notes:
      'Please register at the Circulation Desk. Setup S1 with 25 chairs, facing projector wall.',
    time_start: '2019-07-09 13:00:00',
    time_end: '2019-07-09 14:00:00',
    status: 'Please Contact the library to Register',
    subject1: 'Adult ',
    subject2: 'Health',
    DateTimeStr: 'Tuesday, Jul 16 2019 1:00pm-2:00pm',
    location: 'EI-South Meeting Room',
    instructor: 'Christine Ahearn',
    statusStr: 'Please Contact the Library to Register',
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1539',
    code: 'EIA107',
    title: 'Overeaters Anonymous ',
    desc: 'Support group helping people with different types of problems.',
    notes: 'Set up: Table with 15 chairs',
    time_start: '2019-07-09 10:00:00',
    time_end: '2019-07-09 11:00:00',
    status: 'No registration required',
    subject1: 'Adult ',
    subject2: 'Health',
    DateTimeStr: 'Tuesday, Jul 23 2019 10:00am-11:00am',
    location: 'EI-East Meeting Room',
    instructor: '',
    statusStr: 'No Registration Required',
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1540',
    code: 'EIA141',
    title: 'Scrabble and Mahjongg ',
    desc: 'The Library will provide the space for you to join your friends for a game of Scrabble and/or Mahjongg.',
    notes: 'Setup: S5',
    time_start: '2019-07-09 12:00:00',
    time_end: '2019-07-09 15:00:00',
    status: 'No registration required',
    subject1: 'Adult ',
    subject2: 'Games',
    DateTimeStr: 'Tuesday, Jul 23 2019 12:00pm-3:00pm',
    location: "EI-Children's Mtg Room",
    instructor: '',
    statusStr: 'No Registration Required',
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1538',
    code: 'EIY733',
    title: 'Nailed It! - Young Adult ',
    desc: 'Teen chefs will work as a team and try to recreate professional dessert items. Will you nail it or fail it? For those entering grades 6-12.',
    notes:
      'Please note your grade in the "Note to Instructor" field to ensure your place in the program.  Setup: N3 for 20, CT',
    time_start: '2019-07-08 19:00:00',
    time_end: '2019-07-08 20:00:00',
    status: 'Closed',
    subject1: 'Young Adult ',
    subject2: 'Cooking',
    DateTimeStr: null,
    location: null,
    instructor: null,
    statusStr: null,
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1537',
    code: 'EIA312',
    title: 'Yoga ',
    desc: 'For those of you who cannot make our daytime yoga classes, we are now offering an evening class with Joy.  If you have always wanted to experience the benefits of yoga, improvement in flexibility, strength, posture, and more give this class a try.  Please bring a mat or towel.',
    notes:
      'Please register for this program at the Circulation Desk. Setup: S4.',
    time_start: '2019-07-08 19:00:00',
    time_end: '2019-07-08 20:00:00',
    status: 'Please Contact the library to Register',
    subject1: 'Adult ',
    subject2: 'Health',
    DateTimeStr: 'Monday, Jul 15 2019 7:00pm-8:00pm',
    location: 'EI-South Meeting Room',
    instructor: 'Joy Walker',
    statusStr: 'Please Contact the Library to Register',
    Month: '7',
    updated: '2019-07-12 13:45:46'
  },
  {
    id: '1536',
    code: 'EIA406',
    title: 'Yoga ',
    desc: 'If you always wanted to experience the benefits of yoga - improvement in flexibility, strength, posture, and more - join Joy and give this class a try. Bring a towel or a mat.',
    notes: 'Please sign up at the Circulation Desk. Setup: S4.',
    time_start: '2019-07-08 17:30:00',
    time_end: '2019-07-08 18:30:00',
    status: 'Please Contact the library to Register',
    subject1: 'Adult ',
    subject2: 'Exercise',
    DateTimeStr: 'Monday, Jul 15 2019 5:30pm-6:30pm',
    location: 'EI-South Meeting Room',
    instructor: 'Joy Walker',
    statusStr: 'Please Contact the Library to Register',
    Month: '7',
    updated: '2019-07-12 13:45:46'
  }
]

export default programs
