var render = function () {
  var _vm$user, _vm$user$azure;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "6"
    }
  }, [_c('card-daily-info')], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_vm.$vuetify.breakpoint.mdAndUp ? _c('v-card-text', {
    staticClass: "d-flex align-start justify-end"
  }, _vm._l(_vm.quickLinks, function (qlink) {
    return _c('btn-icon-label', {
      key: qlink.icon,
      staticClass: "flex-shrink-1",
      attrs: {
        "data": qlink
      }
    });
  }), 1) : _c('v-card-text', {
    staticClass: "pt-0"
  }, _vm._l(_vm.quickLinks, function (qlink) {
    return _c('card-link-horizontal', {
      key: qlink.icon,
      staticClass: "flex-shrink-1 my-1",
      attrs: {
        "data": qlink
      }
    });
  }), 1)], 1)], 1), _vm.weatherForecastShow ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-slide-y-transition', [_c('v-card', {
    attrs: {
      "flat": "",
      "min-height": "119",
      "scrollable": "",
      "tile": ""
    }
  }, [_c('v-card-title', {
    staticClass: "primary--text"
  }, [_c('v-icon', {
    attrs: {
      "color": "primary",
      "left": ""
    }
  }, [_vm._v("mdi-weather-partly-snowy-rainy")]), _vm._v("WEATHER")], 1), _c('v-card-text', {
    staticClass: "scrollable-card primary--text"
  }, [_c('weather')], 1)], 1)], 1)], 1) : _vm._e(), _c('v-col', {
    staticClass: "my-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('messages-card', {
    attrs: {
      "emails": ((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : (_vm$user$azure = _vm$user.azure) === null || _vm$user$azure === void 0 ? void 0 : _vm$user$azure.unreadEmail) || [],
      "messages": _vm.messageList
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "scrollable": "",
      "tile": "",
      "color": "primary"
    }
  }, [_c('v-card-title', {
    staticClass: "secondary--text"
  }, [_c('badge', {
    attrs: {
      "value": _vm.programList.length > 0,
      "badgeContent": _vm.programList.length
    },
    scopedSlots: _vm._u([{
      key: "child",
      fn: function fn() {
        return [_vm._v(" TODAY'S PROGRAMS ")];
      },
      proxy: true
    }])
  })], 1), _c('v-card-text', {
    ref: "programList",
    staticClass: "scrollable-card secondary",
    style: _vm.programCardTextStyle()
  }, [_c('program-list', {
    attrs: {
      "programs": _vm.programList
    },
    on: {
      "click": _vm.onProgramClick
    }
  })], 1), _c('v-card-actions')], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c('v-card-title', {
    staticClass: "primary--text"
  }, [_c('v-icon', {
    attrs: {
      "color": "primary",
      "left": ""
    }
  }, [_vm._v("mdi-tools")]), _vm._v("STAFF TOOLS")], 1), _vm.$vuetify.breakpoint.smAndUp ? _c('v-card-text', {
    staticClass: "d-flex flex-wrap align-start",
    class: _vm.$vuetify.breakpoint.xs ? 'justify-center' : 'justify-start'
  }, _vm._l(_vm.appList, function (app) {
    return _c('card-link-vertical', _vm._b({
      key: app.label,
      staticClass: "ma-2"
    }, 'card-link-vertical', app, false));
  }), 1) : _c('v-card-text', _vm._l(_vm.appList, function (app) {
    return _c('card-link-horizontal', {
      key: app.icon,
      staticClass: "flex-shrink-1 my-1",
      attrs: {
        "data": app
      }
    });
  }), 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "value": _vm.modal.value,
      "max-width": "40rem",
      "width": "100vw"
    },
    on: {
      "input": function input($event) {
        return _vm.$store.dispatch('closeModal');
      }
    }
  }, [_c(_vm.modal.component, _vm._b({
    tag: "component"
  }, 'component', _vm.modal.data, false))], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }