<template>
  <v-list color="transparent" three-line>
    <v-list-item v-if="!programs || programs.length < 1">
      <v-list-item-content>
        <v-list-item-title
          class="text-subtitle-1 primary--text font-weight-black pb-1"
          >No programs found.
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <template v-for="program in programsDisplayed">
      <program-list-item
        :key="program.id"
        :program="program"
        @click="displayProgramModal"
      ></program-list-item>
      <v-divider :key="`d${program.id}`"></v-divider>
    </template>
    <!-- extra list item so list has room to expand at bottom-->
    <!-- <v-list-item></v-list-item> -->
  </v-list>
</template>
<script>
import programListItem from '@/components/program-list-item.vue'

export default {
  name: 'program-list',
  components: { programListItem },
  props: {
    programs: {
      type: Array,
      required: true
    }
  },
  // data: () => ({
  //   contentLoading: true,
  //   listItemExpanded: null,
  //   programs: null,
  //   programsUpcoming: [],
  //   showProgramModal: false,
  //   programModalProgram: {}
  // }),
  computed: {
    programsDisplayed() {
      return [...this.programs].sort(
        (a, b) =>
          new Date(`${a.date} ${a.time_start}`) -
          new Date(`${b.date} ${b.time_start}`)
      )
    }
  },
  methods: {
    displayProgramModal(program) {
      this.$store.dispatch('setModal', {
        component: 'program-card',
        data: {
          program: program,
          withActions: true
        },
        options: {},
        value: true
      })
    }
  }
}
</script>
