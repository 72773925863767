<script>
import tempData from '@/data/tempWeather.js'
//import api from '@/utils/api.js'
import { mapState } from 'vuex'
export default {
  name: 'weather-minimal',
  data: () => ({
    alerts: null,
    current: {},
    error: null,
    forecast: [],
    isLoading: false
  }),
  computed: {
    ...mapState({
      weatherForecastShow: (state) => state.weatherForecastShow
    })
  },
  methods: {
    getDay(date = null, dateOptions = { weekday: 'short' }) {
      return new Intl.DateTimeFormat('en-US', dateOptions).format(
        new Date(date * 1000)
      )
    },
    getIcon(iconName = '') {
      if (!iconName) return '' //TODO: SET PLACE HOLDER
      return `https://openweathermap.org/img/wn/${iconName}@2x.png`
    },
    getWeather() {
      //TODO: Call API
      return tempData
    },
    formatCurrentWeather(raw) {
      const currentWeather = { ...raw }
      const weather = raw.weather[0]
      currentWeather.dayOfWeek = 'Now'
      currentWeather.description = weather.description
      currentWeather.icon = this.getIcon(weather.icon)
      return currentWeather
    },
    formatWeatherData(raw) {
      const weatherData = { ...raw }
      const weather = weatherData.weather[0]
      weatherData.dayOfWeek = this.getDay(raw.dt)
      weatherData.icon = this.getIcon(weather.icon)

      return weatherData
    }
  },
  mounted() {
    const weather = this.getWeather()
    const { alerts, current, daily } = weather
    this.alerts = alerts //this.formatAlerts ?
    this.current = this.formatCurrentWeather(current)
    this.forecast = daily.map((day) => this.formatWeatherData(day))
  }
}
</script>
<template>
  <v-card flat tile>
    <v-card-text class="d-flex align-center justify-space-between px-1 py-0">
      <!-- <p class="primary--text font-weight-bold text-h6 ma-0">
                {{ current.dayOfWeek }}
              </p> -->
      <v-img :src="current.icon" contain max-height="40" max-width="40"></v-img>
      <v-sheet class="d-flex flex-column align-start justify-start">
        <p class="primary--text text-subtitle-1 font-weight-bold ma-0">
          {{ Math.round(current.temp) }}º
        </p>
        <p class="primary--text text-caption font-weight-bold ma-0 mt-n2">
          {{ current.description }}
        </p>
      </v-sheet>
      <v-btn
        icon
        color="primary"
        @click="$store.dispatch('toggleWeatherForecastShow')"
        ><v-icon>{{
          `mdi-menu-${weatherForecastShow ? 'up' : 'down'}`
        }}</v-icon></v-btn
      >
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped></style>
