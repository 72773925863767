<script>
export default {
  name: 'card-icon-title',
  props: {
    href: {
      type: String,
      default: ''
    },

    icon: {
      type: String
    },
    iconColor: {
      type: String,
      default: 'primary'
    },
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    isXSmall() {
      return this.$vuetify.breakpoint.xs
    }
  }
}
</script>

<style lang="scss" scoped></style>
<template>
  <v-card
    :height="isXSmall ? '4.5rem' : '45px'"
    :href="href"
    flat
    target="_blank"
    tile
    :width="isXSmall ? '5rem' : '100%'"
    class="primary--text d-flex align-center text-truncate"
    :class="`${
      isXSmall ? 'flex-column justify-space-around' : 'justify-center'
    }`"
    hover
    @click="$emit('click', { icon, label })"
  >
    <v-icon :left="!isXSmall" :color="iconColor || 'primary'">{{
      `mdi-${icon}`
    }}</v-icon>
    <br v-if="isXSmall" />
    <span class="ml-1 font-weight-bold" :class="isXSmall ? 'caption' : ''">{{
      label
    }}</span>
  </v-card>
</template>
