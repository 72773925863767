var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "scrollable": "",
      "tile": ""
    }
  }, [_c('v-card-title', [_c('badge', {
    attrs: {
      "value": _vm.emails.length > 0,
      "badgeContent": _vm.emails.length
    },
    scopedSlots: _vm._u([{
      key: "child",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "color": "primary",
            "title": "Unread Email."
          }
        }, [_vm._v("mdi-email")])];
      },
      proxy: true
    }])
  })], 1), _c('v-card-text', {
    ref: "emails",
    staticClass: "scrollable-card secondary"
  }, [_vm._l(_vm.emails, function (email) {
    return [_c('email-list-item', {
      key: email.receivedDateTime.toString(),
      attrs: {
        "email": email
      }
    }), _c('v-divider', {
      key: email.receivedDateTime.toString() + 'd',
      attrs: {
        "inset": ""
      }
    })];
  })], 2), _c('v-card-actions')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }