<template>
  <v-card min-width="250" tile>
    <v-card-text class="d-flex flex-column align-center justify-space-between">
      <p
        :title="humanDate(forecast.dt * 1000)"
        class="primary--text text text-h6 font-weight-bold ma-0"
      >
        {{ forecast.dayOfWeek }}
      </p>
      <v-img
        contain
        eager
        :src="forecast.icon"
        max-height="85"
        width="85"
      ></v-img>
      <p
        :title="`Feels like: ${Math.round(forecast.feels_like.day)}º`"
        class="temp ma-0 font-weight-bold primary--text text-h4"
      >
        {{ Math.round(forecast.temp.day) }}º
      </p>
      <p class="primary--text font-weight-bold text-capitalize ma-0">
        {{ forecast.description }}
      </p>
    </v-card-text>
    <v-card-actions class="d-flex align-center justify-space-around">
      <v-card
        flat
        tile
        :title="`Wind: ${forecast.wind_speed}mph ${forecast.wind_compass}`"
        class="d-flex flex-column align-center justify-center flex-grow-1"
      >
        <p class="temp ma-0 primary--text font-weight-medium text-body-1">
          {{ Math.round(forecast.wind_speed) }}
        </p>
        <!-- º -->
        <v-icon
          color="primary"
          class="mt-2"
          :style="`transform: rotate(${forecast.wind_deg}deg)`"
          >mdi-navigation</v-icon
        >
      </v-card>
      <v-card
        flat
        tile
        :title="`Chance of Percipitation: ${forecast.pop * 100}%`"
        class="d-flex flex-column align-center justify-center flex-grow-1"
      >
        <p class="temp ma-0 primary--text font-weight-medium text-body-1">
          {{ forecast.pop * 100 }}
        </p>
        <v-icon color="primary" class="mt-2">mdi-water-percent</v-icon>
      </v-card>
      <!-- <v-card flat tile width="99%" class="d-flex flex-column align-center justify-center flex-grow-1">
        <p class="temp ma-0 font-weight-bold primary--text font-weight-medium text-body-1">69º</p>
     
        <v-icon color="primary" class="mt-2">mdi-thermometer-lines</v-icon>
      </v-card> -->
      <v-card
        flat
        tile
        :title="`UV Index: ${forecast.uvi}/10`"
        class="d-flex flex-column align-center justify-center flex-grow-1"
      >
        <p class="temp ma-0 primary--text font-weight-medium text-body-1">
          {{ forecast.uvi }}
        </p>
        <v-icon color="primary" class="mt-2">mdi-sun-wireless-outline</v-icon>
      </v-card>
    </v-card-actions>
  </v-card>
</template>

<script>
import datetimeMixin from '@/mixins/datetime-mixin.vue'
export default {
  name: 'weather-forecast-day',
  mixins: [datetimeMixin],
  props: {
    forecast: {
      type: Object,
      reequired: true
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>
// .temp::after {
//   content: 'º';
// }
</style>
