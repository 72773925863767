var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "hover": "",
      "tile": "",
      "title": _vm.data.title,
      "width": "100%",
      "href": _vm.data.to,
      "target": "_blank"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('v-card-text', {
    staticClass: "d-flex align-center primary--text font-weight-bold"
  }, [_c('v-icon', {
    attrs: {
      "color": "primary",
      "left": ""
    }
  }, [_vm._v(_vm._s("mdi-".concat(_vm.data.icon)))]), _c('v-sheet', {
    staticClass: "d-flex align-center flex-grow-1 primary--text font-weight-bold",
    attrs: {
      "color": "transparent"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.text) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }