var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    attrs: {
      "color": "transparent",
      "three-line": ""
    }
  }, [!_vm.programs || _vm.programs.length < 1 ? _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-subtitle-1 primary--text font-weight-black pb-1"
  }, [_vm._v("No programs found. ")])], 1)], 1) : _vm._e(), _vm._l(_vm.programsDisplayed, function (program) {
    return [_c('program-list-item', {
      key: program.id,
      attrs: {
        "program": program
      },
      on: {
        "click": _vm.displayProgramModal
      }
    }), _c('v-divider', {
      key: "d".concat(program.id)
    })];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }