const apps = [
  {
    href: 'https://eipl.org/bulletinboard/manage',
    icon: 'bulletin-board',
    text: 'BULLETIN BOARD',
    title: 'https://eipl.org/bulletinboard/manage'
  },
  {
    href: 'https://eipl.org/staff/clod',
    icon: 'card-text',
    text: 'CLOD',
    title: 'https://eipl.org/staff/clod'
  },
  {
    href: 'https://eipl.org/staff/covid',
    icon: 'virus',
    text: 'COVID',
    title: 'https://eipl.org/staff/covid'
  },
  {
    href: 'https://eipl.org/staff/labLog',
    icon: 'desktop-classic',
    text: 'LAB LOG',
    title: 'https://eipl.org/staff/labLog'
  },
  {
    href: 'https://eipl.org/staff/newMaterial',
    icon: 'book-plus-multiple',
    text: 'NEW MATERIAL',
    title: 'https://eipl.org/staff/newMaterial'
  },
  {
    href: 'https://eipl.org/reservations_new/',
    icon: 'calendar-clock',
    text: 'RESERVATION CALENDAR',
    title: 'https://eipl.org/reservations_new/'
  },
  {
    href: 'https://eipl.org/staff/signage/manage',
    icon: ' mdi-tablet-dashboard',
    text: 'SIGNAGE',
    title: 'https://eipl.org/staff/signage/manage'
  },
  {
    href: 'https://eipl.org/staff/urlShortener',
    icon: 'link-box-variant-outline',
    text: 'URL SHORTENER',
    title: 'https://eipl.org/staff/urlShortener'
  },
  {
    href: '/cms',
    icon: 'tools',
    text: 'WEBSITE MANAGEMENT',
    title: '#'
  },
  {
    href: '#',
    icon: 'book-information-variant',
    text: 'WIKI',
    title: '#'
  },
  {
    href: '#',
    icon: 'wifi',
    text: 'WIFI STATS',
    title: '#'
  }
]
export default apps
