const weather = {
  lat: 40.7427,
  lon: -73.2138,
  timezone: 'America/New_York',
  timezone_offset: -14400,
  current: {
    dt: 1652193994,
    sunrise: 1652175648,
    sunset: 1652227066,
    temp: 60.71,
    feels_like: 58.03,
    pressure: 1027,
    humidity: 33,
    dew_point: 31.53,
    uvi: 5.76,
    clouds: 20,
    visibility: 10000,
    wind_speed: 25.32,
    wind_deg: 30,
    wind_gust: 36.82,
    weather: [
      { id: 801, main: 'Clouds', description: 'few clouds', icon: '02d' }
    ]
  },
  hourly: [
    {
      dt: 1652191200,
      temp: 59.81,
      feels_like: 57.13,
      pressure: 1027,
      humidity: 35,
      dew_point: 32.13,
      uvi: 4.03,
      clouds: 17,
      visibility: 10000,
      wind_speed: 18.37,
      wind_deg: 41,
      wind_gust: 32.75,
      weather: [
        { id: 801, main: 'Clouds', description: 'few clouds', icon: '02d' }
      ],
      pop: 0
    },
    {
      dt: 1652194800,
      temp: 60.71,
      feels_like: 58.03,
      pressure: 1027,
      humidity: 33,
      dew_point: 31.53,
      uvi: 5.76,
      clouds: 20,
      visibility: 10000,
      wind_speed: 18.77,
      wind_deg: 44,
      wind_gust: 31.81,
      weather: [
        { id: 801, main: 'Clouds', description: 'few clouds', icon: '02d' }
      ],
      pop: 0
    },
    {
      dt: 1652198400,
      temp: 60.91,
      feels_like: 58.21,
      pressure: 1027,
      humidity: 32,
      dew_point: 31.01,
      uvi: 6.23,
      clouds: 16,
      visibility: 10000,
      wind_speed: 18.5,
      wind_deg: 50,
      wind_gust: 30.33,
      weather: [
        { id: 801, main: 'Clouds', description: 'few clouds', icon: '02d' }
      ],
      pop: 0
    },
    {
      dt: 1652202000,
      temp: 61.74,
      feels_like: 59.11,
      pressure: 1027,
      humidity: 32,
      dew_point: 31.66,
      uvi: 6.58,
      clouds: 13,
      visibility: 10000,
      wind_speed: 17.36,
      wind_deg: 54,
      wind_gust: 28.5,
      weather: [
        { id: 801, main: 'Clouds', description: 'few clouds', icon: '02d' }
      ],
      pop: 0
    },
    {
      dt: 1652205600,
      temp: 62.85,
      feels_like: 60.35,
      pressure: 1026,
      humidity: 32,
      dew_point: 32.58,
      uvi: 6.06,
      clouds: 9,
      visibility: 10000,
      wind_speed: 17.13,
      wind_deg: 58,
      wind_gust: 28.45,
      weather: [
        { id: 800, main: 'Clear', description: 'clear sky', icon: '01d' }
      ],
      pop: 0
    },
    {
      dt: 1652209200,
      temp: 63.5,
      feels_like: 61.14,
      pressure: 1026,
      humidity: 34,
      dew_point: 34.66,
      uvi: 2.9,
      clouds: 4,
      visibility: 10000,
      wind_speed: 17.05,
      wind_deg: 66,
      wind_gust: 27.4,
      weather: [
        { id: 800, main: 'Clear', description: 'clear sky', icon: '01d' }
      ],
      pop: 0
    },
    {
      dt: 1652212800,
      temp: 62.6,
      feels_like: 60.15,
      pressure: 1026,
      humidity: 34,
      dew_point: 33.71,
      uvi: 1.96,
      clouds: 19,
      visibility: 10000,
      wind_speed: 17.16,
      wind_deg: 71,
      wind_gust: 27.78,
      weather: [
        { id: 801, main: 'Clouds', description: 'few clouds', icon: '02d' }
      ],
      pop: 0.01
    },
    {
      dt: 1652216400,
      temp: 59.34,
      feels_like: 56.62,
      pressure: 1027,
      humidity: 35,
      dew_point: 31.62,
      uvi: 1.08,
      clouds: 46,
      visibility: 10000,
      wind_speed: 14.7,
      wind_deg: 55,
      wind_gust: 28.25,
      weather: [
        {
          id: 802,
          main: 'Clouds',
          description: 'scattered clouds',
          icon: '03d'
        }
      ],
      pop: 0.01
    },
    {
      dt: 1652220000,
      temp: 57.87,
      feels_like: 54.86,
      pressure: 1027,
      humidity: 32,
      dew_point: 27.86,
      uvi: 0.83,
      clouds: 60,
      visibility: 10000,
      wind_speed: 14.63,
      wind_deg: 49,
      wind_gust: 29.89,
      weather: [
        { id: 803, main: 'Clouds', description: 'broken clouds', icon: '04d' }
      ],
      pop: 0.01
    },
    {
      dt: 1652223600,
      temp: 56.93,
      feels_like: 53.87,
      pressure: 1027,
      humidity: 33,
      dew_point: 28,
      uvi: 0.22,
      clouds: 68,
      visibility: 10000,
      wind_speed: 14.38,
      wind_deg: 47,
      wind_gust: 30.58,
      weather: [
        { id: 803, main: 'Clouds', description: 'broken clouds', icon: '04d' }
      ],
      pop: 0.01
    },
    {
      dt: 1652227200,
      temp: 56.52,
      feels_like: 53.56,
      pressure: 1027,
      humidity: 36,
      dew_point: 29.57,
      uvi: 0,
      clouds: 72,
      visibility: 10000,
      wind_speed: 14.36,
      wind_deg: 48,
      wind_gust: 29.93,
      weather: [
        { id: 803, main: 'Clouds', description: 'broken clouds', icon: '04n' }
      ],
      pop: 0
    },
    {
      dt: 1652230800,
      temp: 54.36,
      feels_like: 51.28,
      pressure: 1028,
      humidity: 38,
      dew_point: 29.23,
      uvi: 0,
      clouds: 16,
      visibility: 10000,
      wind_speed: 14.45,
      wind_deg: 45,
      wind_gust: 31.79,
      weather: [
        { id: 801, main: 'Clouds', description: 'few clouds', icon: '02n' }
      ],
      pop: 0
    },
    {
      dt: 1652234400,
      temp: 53.01,
      feels_like: 49.84,
      pressure: 1028,
      humidity: 39,
      dew_point: 28.33,
      uvi: 0,
      clouds: 11,
      visibility: 10000,
      wind_speed: 13.78,
      wind_deg: 40,
      wind_gust: 32.14,
      weather: [
        { id: 801, main: 'Clouds', description: 'few clouds', icon: '02n' }
      ],
      pop: 0
    },
    {
      dt: 1652238000,
      temp: 51.87,
      feels_like: 48.6,
      pressure: 1027,
      humidity: 39,
      dew_point: 27.7,
      uvi: 0,
      clouds: 9,
      visibility: 10000,
      wind_speed: 13.24,
      wind_deg: 38,
      wind_gust: 30.91,
      weather: [
        { id: 800, main: 'Clear', description: 'clear sky', icon: '01n' }
      ],
      pop: 0
    },
    {
      dt: 1652241600,
      temp: 50.65,
      feels_like: 47.34,
      pressure: 1027,
      humidity: 41,
      dew_point: 27.95,
      uvi: 0,
      clouds: 8,
      visibility: 10000,
      wind_speed: 13.82,
      wind_deg: 34,
      wind_gust: 32.88,
      weather: [
        { id: 800, main: 'Clear', description: 'clear sky', icon: '01n' }
      ],
      pop: 0
    },
    {
      dt: 1652245200,
      temp: 50.02,
      feels_like: 46.8,
      pressure: 1027,
      humidity: 44,
      dew_point: 29.03,
      uvi: 0,
      clouds: 6,
      visibility: 10000,
      wind_speed: 14.14,
      wind_deg: 31,
      wind_gust: 33.84,
      weather: [
        { id: 800, main: 'Clear', description: 'clear sky', icon: '01n' }
      ],
      pop: 0
    },
    {
      dt: 1652248800,
      temp: 49.57,
      feels_like: 44.46,
      pressure: 1026,
      humidity: 46,
      dew_point: 29.71,
      uvi: 0,
      clouds: 5,
      visibility: 10000,
      wind_speed: 13.53,
      wind_deg: 32,
      wind_gust: 33.98,
      weather: [
        { id: 800, main: 'Clear', description: 'clear sky', icon: '01n' }
      ],
      pop: 0
    },
    {
      dt: 1652252400,
      temp: 49.21,
      feels_like: 43.92,
      pressure: 1026,
      humidity: 48,
      dew_point: 30.06,
      uvi: 0,
      clouds: 0,
      visibility: 10000,
      wind_speed: 13.87,
      wind_deg: 27,
      wind_gust: 34.69,
      weather: [
        { id: 800, main: 'Clear', description: 'clear sky', icon: '01n' }
      ],
      pop: 0
    },
    {
      dt: 1652256000,
      temp: 49.1,
      feels_like: 43.77,
      pressure: 1026,
      humidity: 47,
      dew_point: 29.61,
      uvi: 0,
      clouds: 0,
      visibility: 10000,
      wind_speed: 13.85,
      wind_deg: 24,
      wind_gust: 34.29,
      weather: [
        { id: 800, main: 'Clear', description: 'clear sky', icon: '01n' }
      ],
      pop: 0
    },
    {
      dt: 1652259600,
      temp: 48.69,
      feels_like: 43.12,
      pressure: 1026,
      humidity: 48,
      dew_point: 29.7,
      uvi: 0,
      clouds: 2,
      visibility: 10000,
      wind_speed: 14.34,
      wind_deg: 23,
      wind_gust: 34.29,
      weather: [
        { id: 800, main: 'Clear', description: 'clear sky', icon: '01n' }
      ],
      pop: 0
    },
    {
      dt: 1652263200,
      temp: 48.7,
      feels_like: 43.16,
      pressure: 1026,
      humidity: 49,
      dew_point: 30.24,
      uvi: 0,
      clouds: 20,
      visibility: 10000,
      wind_speed: 14.32,
      wind_deg: 28,
      wind_gust: 34.54,
      weather: [
        { id: 801, main: 'Clouds', description: 'few clouds', icon: '02d' }
      ],
      pop: 0
    },
    {
      dt: 1652266800,
      temp: 49.32,
      feels_like: 43.99,
      pressure: 1027,
      humidity: 50,
      dew_point: 31.32,
      uvi: 0.33,
      clouds: 36,
      visibility: 10000,
      wind_speed: 14.14,
      wind_deg: 32,
      wind_gust: 35.25,
      weather: [
        {
          id: 802,
          main: 'Clouds',
          description: 'scattered clouds',
          icon: '03d'
        }
      ],
      pop: 0
    },
    {
      dt: 1652270400,
      temp: 49.89,
      feels_like: 44.76,
      pressure: 1027,
      humidity: 53,
      dew_point: 33.22,
      uvi: 1.06,
      clouds: 46,
      visibility: 10000,
      wind_speed: 13.94,
      wind_deg: 32,
      wind_gust: 34.76,
      weather: [
        {
          id: 802,
          main: 'Clouds',
          description: 'scattered clouds',
          icon: '03d'
        }
      ],
      pop: 0
    },
    {
      dt: 1652274000,
      temp: 51.6,
      feels_like: 48.99,
      pressure: 1027,
      humidity: 54,
      dew_point: 35.2,
      uvi: 2.39,
      clouds: 100,
      visibility: 10000,
      wind_speed: 13.24,
      wind_deg: 36,
      wind_gust: 33.31,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d' }
      ],
      pop: 0
    },
    {
      dt: 1652277600,
      temp: 54.7,
      feels_like: 52.41,
      pressure: 1027,
      humidity: 54,
      dew_point: 38.46,
      uvi: 4.15,
      clouds: 100,
      visibility: 10000,
      wind_speed: 14.09,
      wind_deg: 38,
      wind_gust: 28.7,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d' }
      ],
      pop: 0
    },
    {
      dt: 1652281200,
      temp: 58.95,
      feels_like: 57.16,
      pressure: 1026,
      humidity: 56,
      dew_point: 43.38,
      uvi: 5.92,
      clouds: 97,
      visibility: 10000,
      wind_speed: 14.23,
      wind_deg: 43,
      wind_gust: 25.57,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d' }
      ],
      pop: 0
    },
    {
      dt: 1652284800,
      temp: 63.21,
      feels_like: 61.86,
      pressure: 1026,
      humidity: 56,
      dew_point: 47.03,
      uvi: 7.29,
      clouds: 73,
      visibility: 10000,
      wind_speed: 13.96,
      wind_deg: 48,
      wind_gust: 23.96,
      weather: [
        { id: 803, main: 'Clouds', description: 'broken clouds', icon: '04d' }
      ],
      pop: 0
    },
    {
      dt: 1652288400,
      temp: 65.82,
      feels_like: 64.63,
      pressure: 1025,
      humidity: 54,
      dew_point: 48.6,
      uvi: 7.69,
      clouds: 59,
      visibility: 10000,
      wind_speed: 13.49,
      wind_deg: 48,
      wind_gust: 22.75,
      weather: [
        { id: 803, main: 'Clouds', description: 'broken clouds', icon: '04d' }
      ],
      pop: 0
    },
    {
      dt: 1652292000,
      temp: 66.81,
      feels_like: 65.79,
      pressure: 1025,
      humidity: 55,
      dew_point: 49.59,
      uvi: 7.08,
      clouds: 58,
      visibility: 10000,
      wind_speed: 13.09,
      wind_deg: 57,
      wind_gust: 21.74,
      weather: [
        { id: 803, main: 'Clouds', description: 'broken clouds', icon: '04d' }
      ],
      pop: 0
    },
    {
      dt: 1652295600,
      temp: 65.12,
      feels_like: 64.2,
      pressure: 1025,
      humidity: 61,
      dew_point: 50.94,
      uvi: 5.65,
      clouds: 100,
      visibility: 10000,
      wind_speed: 12.68,
      wind_deg: 67,
      wind_gust: 21.68,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d' }
      ],
      pop: 0
    },
    {
      dt: 1652299200,
      temp: 63.52,
      feels_like: 62.62,
      pressure: 1024,
      humidity: 65,
      dew_point: 51.42,
      uvi: 3.82,
      clouds: 100,
      visibility: 10000,
      wind_speed: 13.27,
      wind_deg: 72,
      wind_gust: 23.24,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d' }
      ],
      pop: 0
    },
    {
      dt: 1652302800,
      temp: 62.92,
      feels_like: 62.06,
      pressure: 1025,
      humidity: 67,
      dew_point: 51.73,
      uvi: 2.11,
      clouds: 100,
      visibility: 10000,
      wind_speed: 13.2,
      wind_deg: 69,
      wind_gust: 26.28,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d' }
      ],
      pop: 0
    },
    {
      dt: 1652306400,
      temp: 61.03,
      feels_like: 60.17,
      pressure: 1025,
      humidity: 71,
      dew_point: 51.57,
      uvi: 0.89,
      clouds: 100,
      visibility: 10000,
      wind_speed: 13.78,
      wind_deg: 65,
      wind_gust: 29.68,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d' }
      ],
      pop: 0
    },
    {
      dt: 1652310000,
      temp: 59.45,
      feels_like: 58.57,
      pressure: 1025,
      humidity: 74,
      dew_point: 51.12,
      uvi: 0.24,
      clouds: 100,
      visibility: 10000,
      wind_speed: 13.22,
      wind_deg: 58,
      wind_gust: 29.62,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d' }
      ],
      pop: 0
    },
    {
      dt: 1652313600,
      temp: 58.15,
      feels_like: 57.34,
      pressure: 1025,
      humidity: 78,
      dew_point: 51.1,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 11.74,
      wind_deg: 54,
      wind_gust: 27,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n' }
      ],
      pop: 0
    },
    {
      dt: 1652317200,
      temp: 57.49,
      feels_like: 56.7,
      pressure: 1026,
      humidity: 80,
      dew_point: 51.1,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 11.34,
      wind_deg: 53,
      wind_gust: 26.13,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n' }
      ],
      pop: 0
    },
    {
      dt: 1652320800,
      temp: 56.97,
      feels_like: 56.21,
      pressure: 1026,
      humidity: 82,
      dew_point: 51.12,
      uvi: 0,
      clouds: 99,
      visibility: 10000,
      wind_speed: 10.51,
      wind_deg: 52,
      wind_gust: 24.4,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n' }
      ],
      pop: 0
    },
    {
      dt: 1652324400,
      temp: 56.61,
      feels_like: 55.92,
      pressure: 1025,
      humidity: 84,
      dew_point: 51.53,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 10,
      wind_deg: 55,
      wind_gust: 23.38,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n' }
      ],
      pop: 0
    },
    {
      dt: 1652328000,
      temp: 56.14,
      feels_like: 55.49,
      pressure: 1025,
      humidity: 86,
      dew_point: 51.64,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 9.57,
      wind_deg: 55,
      wind_gust: 22.97,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n' }
      ],
      pop: 0
    },
    {
      dt: 1652331600,
      temp: 55.81,
      feels_like: 55.18,
      pressure: 1025,
      humidity: 87,
      dew_point: 51.94,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 9.17,
      wind_deg: 57,
      wind_gust: 22.19,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n' }
      ],
      pop: 0
    },
    {
      dt: 1652335200,
      temp: 55.38,
      feels_like: 54.81,
      pressure: 1025,
      humidity: 89,
      dew_point: 52.05,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 8.34,
      wind_deg: 49,
      wind_gust: 19.95,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n' }
      ],
      pop: 0
    },
    {
      dt: 1652338800,
      temp: 55.02,
      feels_like: 54.45,
      pressure: 1025,
      humidity: 90,
      dew_point: 52.02,
      uvi: 0,
      clouds: 99,
      visibility: 10000,
      wind_speed: 8.61,
      wind_deg: 42,
      wind_gust: 19.26,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n' }
      ],
      pop: 0
    },
    {
      dt: 1652342400,
      temp: 54.79,
      feels_like: 54.25,
      pressure: 1025,
      humidity: 91,
      dew_point: 52.05,
      uvi: 0,
      clouds: 99,
      visibility: 10000,
      wind_speed: 9.26,
      wind_deg: 35,
      wind_gust: 20.78,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n' }
      ],
      pop: 0
    },
    {
      dt: 1652346000,
      temp: 54.63,
      feels_like: 54.1,
      pressure: 1025,
      humidity: 92,
      dew_point: 52.25,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 9.6,
      wind_deg: 36,
      wind_gust: 20.96,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04n' }
      ],
      pop: 0
    },
    {
      dt: 1652349600,
      temp: 54.72,
      feels_like: 54.21,
      pressure: 1026,
      humidity: 92,
      dew_point: 52.41,
      uvi: 0,
      clouds: 100,
      visibility: 10000,
      wind_speed: 9.48,
      wind_deg: 37,
      wind_gust: 21.12,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d' }
      ],
      pop: 0
    },
    {
      dt: 1652353200,
      temp: 55.2,
      feels_like: 54.75,
      pressure: 1026,
      humidity: 92,
      dew_point: 52.57,
      uvi: 0.36,
      clouds: 100,
      visibility: 10000,
      wind_speed: 9.69,
      wind_deg: 38,
      wind_gust: 21.36,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d' }
      ],
      pop: 0
    },
    {
      dt: 1652356800,
      temp: 56.05,
      feels_like: 55.58,
      pressure: 1027,
      humidity: 90,
      dew_point: 52.93,
      uvi: 1.16,
      clouds: 100,
      visibility: 10000,
      wind_speed: 9.24,
      wind_deg: 48,
      wind_gust: 20.94,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d' }
      ],
      pop: 0
    },
    {
      dt: 1652360400,
      temp: 57.65,
      feels_like: 57.16,
      pressure: 1027,
      humidity: 86,
      dew_point: 53.24,
      uvi: 2.63,
      clouds: 100,
      visibility: 10000,
      wind_speed: 9.44,
      wind_deg: 53,
      wind_gust: 17.47,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d' }
      ],
      pop: 0
    }
  ],
  daily: [
    {
      dt: 1652198400,
      sunrise: 1652175648,
      sunset: 1652227066,
      moonrise: 1652205000,
      moonset: 1652166300,
      moon_phase: 0.3,
      temp: {
        day: 60.91,
        min: 47.79,
        max: 63.5,
        night: 51.87,
        eve: 57.87,
        morn: 47.79
      },
      feels_like: { day: 58.21, night: 48.6, eve: 54.86, morn: 41.65 },
      pressure: 1027,
      humidity: 32,
      dew_point: 31.01,
      wind_speed: 18.77,
      wind_deg: 44,
      wind_gust: 41,
      weather: [
        { id: 801, main: 'Clouds', description: 'few clouds', icon: '02d' }
      ],
      clouds: 16,
      pop: 0.01,
      uvi: 6.58
    },
    {
      dt: 1652284800,
      sunrise: 1652261985,
      sunset: 1652313527,
      moonrise: 1652295360,
      moonset: 1652254200,
      moon_phase: 0.34,
      temp: {
        day: 63.21,
        min: 48.69,
        max: 66.81,
        night: 56.61,
        eve: 61.03,
        morn: 48.7
      },
      feels_like: { day: 61.86, night: 55.92, eve: 60.17, morn: 43.16 },
      pressure: 1026,
      humidity: 56,
      dew_point: 47.03,
      wind_speed: 14.34,
      wind_deg: 23,
      wind_gust: 35.25,
      weather: [
        { id: 803, main: 'Clouds', description: 'broken clouds', icon: '04d' }
      ],
      clouds: 73,
      pop: 0,
      uvi: 7.69
    },
    {
      dt: 1652371200,
      sunrise: 1652348324,
      sunset: 1652399988,
      moonrise: 1652385780,
      moonset: 1652341980,
      moon_phase: 0.37,
      temp: {
        day: 62.65,
        min: 54.63,
        max: 65.89,
        night: 56.86,
        eve: 63.86,
        morn: 54.72
      },
      feels_like: { day: 62.15, night: 56.61, eve: 63.46, morn: 54.21 },
      pressure: 1027,
      humidity: 75,
      dew_point: 54.63,
      wind_speed: 10.67,
      wind_deg: 94,
      wind_gust: 22.97,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d' }
      ],
      clouds: 100,
      pop: 0,
      uvi: 8.49
    },
    {
      dt: 1652457600,
      sunrise: 1652434664,
      sunset: 1652486448,
      moonrise: 1652476440,
      moonset: 1652429820,
      moon_phase: 0.41,
      temp: {
        day: 63.16,
        min: 56.62,
        max: 66,
        night: 58.71,
        eve: 64.09,
        morn: 56.62
      },
      feels_like: { day: 62.98, night: 58.84, eve: 64.11, morn: 56.41 },
      pressure: 1026,
      humidity: 81,
      dew_point: 57.29,
      wind_speed: 7.67,
      wind_deg: 85,
      wind_gust: 17.2,
      weather: [
        { id: 804, main: 'Clouds', description: 'overcast clouds', icon: '04d' }
      ],
      clouds: 100,
      pop: 0,
      uvi: 7.69
    },
    {
      dt: 1652544000,
      sunrise: 1652521005,
      sunset: 1652572908,
      moonrise: 1652567280,
      moonset: 1652517720,
      moon_phase: 0.44,
      temp: {
        day: 61.43,
        min: 55.9,
        max: 61.43,
        night: 55.9,
        eve: 60.08,
        morn: 57.83
      },
      feels_like: { day: 61.59, night: 55.76, eve: 60.26, morn: 57.92 },
      pressure: 1022,
      humidity: 92,
      dew_point: 58.93,
      wind_speed: 5.5,
      wind_deg: 191,
      wind_gust: 13.27,
      weather: [
        { id: 500, main: 'Rain', description: 'light rain', icon: '10d' }
      ],
      clouds: 100,
      pop: 0.81,
      rain: 0.88,
      uvi: 0.67
    },
    {
      dt: 1652630400,
      sunrise: 1652607348,
      sunset: 1652659367,
      moonrise: 1652658360,
      moonset: 1652605920,
      moon_phase: 0.48,
      temp: {
        day: 57.49,
        min: 55.22,
        max: 63.39,
        night: 56.66,
        eve: 63.39,
        morn: 55.69
      },
      feels_like: { day: 57.45, night: 56.44, eve: 63.23, morn: 55.62 },
      pressure: 1012,
      humidity: 96,
      dew_point: 56.3,
      wind_speed: 9.55,
      wind_deg: 197,
      wind_gust: 29.95,
      weather: [
        { id: 500, main: 'Rain', description: 'light rain', icon: '10d' }
      ],
      clouds: 100,
      pop: 0.88,
      rain: 1.59,
      uvi: 1
    },
    {
      dt: 1652716800,
      sunrise: 1652693693,
      sunset: 1652745826,
      moonrise: 1652749560,
      moonset: 1652694360,
      moon_phase: 0.5,
      temp: {
        day: 69.66,
        min: 56.79,
        max: 73.76,
        night: 66.07,
        eve: 73.76,
        morn: 60.04
      },
      feels_like: { day: 68.58, night: 65.07, eve: 72.39, morn: 60.08 },
      pressure: 1007,
      humidity: 48,
      dew_point: 48.81,
      wind_speed: 13.49,
      wind_deg: 290,
      wind_gust: 29.93,
      weather: [
        { id: 500, main: 'Rain', description: 'light rain', icon: '10d' }
      ],
      clouds: 1,
      pop: 0.45,
      rain: 0.42,
      uvi: 1
    },
    {
      dt: 1652803200,
      sunrise: 1652780039,
      sunset: 1652832284,
      moonrise: 1652840580,
      moonset: 1652783340,
      moon_phase: 0.56,
      temp: {
        day: 67.48,
        min: 59.05,
        max: 75.02,
        night: 63.19,
        eve: 75.02,
        morn: 59.05
      },
      feels_like: { day: 67.08, night: 61.7, eve: 73.87, morn: 59.13 },
      pressure: 1008,
      humidity: 67,
      dew_point: 56.16,
      wind_speed: 12.46,
      wind_deg: 276,
      wind_gust: 28.34,
      weather: [
        { id: 500, main: 'Rain', description: 'light rain', icon: '10d' }
      ],
      clouds: 82,
      pop: 0.84,
      rain: 4.04,
      uvi: 1
    }
  ]
}

export default weather
