<script>
import emailCard from '@/components/email-card.vue'
import communicationsCard from '@/components/communications-card.vue'
export default {
  name: 'messages-card',
  components: { communicationsCard, emailCard },
  props: {
    emails: {
      type: Array,
      default: () => []
    },
    messages: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({}),
  computed: {
    temp() {
      return 'hi'
    }
  }
}
</script>
<template>
  <v-card flat min-height="119" scrollable tile>
    <v-card-title class="primary--text">
      <v-icon color="primary" left>mdi-email-multiple</v-icon> MESSAGES
      <!-- <v-icon color="primary" left>mdi-bullhorn</v-icon> -->
      <!-- <badge :value="messages.length > 0" :badgeContent="messages.length">
        <template v-slot:child>
          <v-icon color="primary">mdi-email-multiple</v-icon> MESSAGES
        </template>
      </badge> -->
    </v-card-title>
    <v-card-text class="scrollable-card primary--text">
      <communications-card :messages="messages"></communications-card>
      <email-card :emails="emails" class="mt-2"></email-card>
    </v-card-text>
  </v-card>
</template>

<style></style>
