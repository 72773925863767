<template>
  <v-btn
    :color="data.color || 'primary'"
    hover
    :href="data.to"
    text
    tile
    :title="data.title"
    target="_blank"
    @click="$emit('click')"
    class="font-weight-bold"
  >
    <v-icon :color="data.color || 'primary'" left>{{
      `mdi-${data.icon}`
    }}</v-icon>

    {{ data.text }}
  </v-btn>
</template>

<script>
export default {
  name: 'card-link',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
