var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "min-height": "119",
      "scrollable": "",
      "tile": ""
    }
  }, [_c('v-card-title', {
    staticClass: "primary--text"
  }, [_c('v-icon', {
    attrs: {
      "color": "primary",
      "left": "",
      "title": "Communications"
    }
  }, [_vm._v("mdi-bullhorn-variant")])], 1), _c('v-card-text', {
    staticClass: "scrollable-card primary--text"
  }, [_vm.messages.length < 1 ? _c('p', {
    staticClass: "font-italic mb-0"
  }, [_vm._v(" No unread messages ")]) : _vm._e(), _vm._l(_vm.messages, function (message) {
    return _c('v-alert', {
      key: message.id,
      attrs: {
        "dismissible": "",
        "tile": "",
        "type": message.type
      },
      on: {
        "input": function input($event) {
          return _vm.onMessageDismiss(message.id);
        }
      }
    }, [_vm._v(_vm._s(message.message))]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }